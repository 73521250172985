define("discourse/plugins/discourse-assign/discourse/templates/connectors/groups-interaction-custom-options/assignable-interaction-fields", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">
      {{i18n "discourse_assign.admin.groups.manage.interaction.assign"}}
    </label>
  
    <label for="visiblity">
      {{i18n
        "discourse_assign.admin.groups.manage.interaction.assignable_levels.title"
      }}
    </label>
  
    <ComboBox
      @name="alias"
      @valueProperty="value"
      @value={{this.assignableLevel}}
      @content={{this.assignableLevelOptions}}
      @class="groups-form-assignable-level"
      @onChange={{action (mut @outletArgs.model.assignable_level)}}
    />
  </div>
  */
  {
    "id": "0P45IC94",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"discourse_assign.admin.groups.manage.interaction.assign\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"label\"],[14,\"for\",\"visiblity\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"discourse_assign.admin.groups.manage.interaction.assignable_levels.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@name\",\"@valueProperty\",\"@value\",\"@content\",\"@class\",\"@onChange\"],[\"alias\",\"value\",[30,0,[\"assignableLevel\"]],[30,0,[\"assignableLevelOptions\"]],\"groups-form-assignable-level\",[28,[37,2],[[30,0],[28,[37,3],[[30,1,[\"model\",\"assignable_level\"]]],null]],null]]],null],[1,\"\\n\"],[13]],[\"@outletArgs\"],false,[\"i18n\",\"combo-box\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-assign/discourse/templates/connectors/groups-interaction-custom-options/assignable-interaction-fields.hbs",
    "isStrictMode": false
  });
});